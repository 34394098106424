var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("WidgetFrame", {
    on: {
      onResize: _vm.onResize,
      onOrientationChange: _vm.onOrientationChange,
    },
    scopedSlots: _vm._u([
      {
        key: "title",
        fn: function () {
          return [_vm._v(" " + _vm._s(_vm.$t("skill.title")) + " ")]
        },
        proxy: true,
      },
      {
        key: "content",
        fn: function () {
          return [
            !_vm.canView("PROJECT", ["TASK"])
              ? _c("div", { staticClass: "center-text" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "entity_selector.error.insufficient_permission_to_show_data"
                        )
                      ) +
                      " "
                  ),
                ])
              : !_vm.loaded
              ? [
                  _c("div", { staticClass: "status-message" }, [
                    _vm._v("Loading..."),
                  ]),
                ]
              : _vm.noTasks
              ? [
                  _c("div", { staticClass: "status-message" }, [
                    _vm._v("No tasks in project."),
                  ]),
                ]
              : [
                  _c(
                    "div",
                    { staticClass: "input-group" },
                    [
                      _c("div", { staticClass: "input-group-prepend" }, [
                        _c(
                          "label",
                          {
                            staticClass: "input-group-text",
                            attrs: { for: "skillsType" },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("dashboard.widgets.view_by")) +
                                " "
                            ),
                          ]
                        ),
                      ]),
                      _c("b-form-select", {
                        attrs: {
                          id: "skillsType",
                          "data-vv-as": _vm.$t("dashboard.widgets.view_by"),
                          "data-vv-name": "dashboard.widgets.view_by",
                          "data-vv-delay": "500",
                          options: _vm.optionTypes,
                          "text-field": "label",
                          "value-field": "key",
                        },
                        model: {
                          value: _vm.type,
                          callback: function ($$v) {
                            _vm.type = $$v
                          },
                          expression: "type",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "chart-holder" },
                    [
                      _vm.loaded
                        ? _c("ag-charts-vue", {
                            attrs: { options: _vm.options },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }